// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
body{
  font-family: 'Jost', sans-serif !important;
}

.date-picker-custom-selected .react-datepicker__day--keyboard-selected {
  background: #dee2e6;
  color: black;
}
.date-picker-custom-selected .react-datepicker__day--disabled {
  background-color: rgba(209, 55, 45, 0.5019607843);
  color: #7d211b;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":";AACA;EACE,0CAA0C;AAC5C;;AAEA;EACE,mBAAmB;EACnB,YAAY;AACd;AACA;EACE,iDAAiD;EACjD,cAAc;AAChB","sourcesContent":["\nbody{\n  font-family: 'Jost', sans-serif !important;\n}\n\n.date-picker-custom-selected .react-datepicker__day--keyboard-selected {\n  background: #dee2e6;\n  color: black;\n}\n.date-picker-custom-selected .react-datepicker__day--disabled {\n  background-color: rgba(209, 55, 45, 0.5019607843);\n  color: #7d211b;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
