import React, { useState, useEffect } from "react"
import {
  ListGroup,
  Col,
  Row,
  Toast,
  ToastContainer,
  ButtonGroup,
  Modal,
  Alert,
} from "react-bootstrap"
import { useSelector, useDispatch } from "react-redux"

import { Persona, ButtonShadow } from "../../components"
import DocumentsTable from "../businessDashboard/components/DocumentsTable"

const MainContext = ({
  item,
  showMain,
  setShowMain,
  setShowPreview,
  deleteClickHandler,
  approvedHandler,
  settingsInfo,
  updateDocumentHandler,
  deleteDocumentHandler,
}) => {
  const [switchFilter, setSwitchFilter] = useState(false)
  const [toast, setToast] = useState(false)
  const [settings, setSettings] = useState()
  const [categoryChosenName, setCategoryNameChosen] = useState(item.category)
  const [docInfoIns, setDocInfoIns] = useState([])
  const [docInfoLic, setDocInfoLic] = useState([])
  const [chooseAlert, setChooseAlert] = useState({})

  const { upload } = useSelector((state) => state.uploadDocument)
  const { success } = useSelector((state) => state.deleteDocument)

  const checkType = (arr) => {
    if (arr.type === "Insurance") {
      setDocInfoIns((y) => [...y, arr])
    } else if (arr.type === "License") {
      setDocInfoLic((y) => [...y, arr])
    }
  }

  useEffect(() => {
    if (item.documents) {
      setDocInfoIns([])
      setDocInfoLic([])
      item.documents.map((x) => checkType(x))
    }
  }, [item])

  useEffect(() => {
    if (upload) {
      checkType(upload)
    }
  }, [upload])
  useEffect(() => {
    if (success) {
    }
  }, [success])
  useEffect(() => {
    if (settingsInfo !== null && settingsInfo !== undefined) {
      setSettings(settingsInfo[0])

      //   setUserServices(settingsInfo[0].usersServices)
      getFullName()
    }
  }, [settingsInfo])

  const formatPhoneNumber = (phoneNumberString) => {
    let cleaned = ("" + phoneNumberString).replace(/\D/g, "")
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3]
    }
    return null
  }
  const getFullName = () => {
    let fn = {}
    if (settings !== undefined) {
      fn = settings.mainServices.find((o) => o.shortName === item.category)
      if (fn !== undefined) {
        setCategoryNameChosen(fn.name)
      } else {
        fn = settings.usersServices.find((o) => o.shortName === item.category)
        setCategoryNameChosen(fn.name)
      }
    }
  }

  const createFormData = (file, typeFile) => {
    const tp = file.name.split(".").pop()
    if (tp === "PDF" || tp === "pdf" || tp === "jpg" || tp === "png") {
      setChooseAlert({
        variant: "success",
        text: "File uploaded successfully",
      })
      const formData = new FormData()
      formData.append("file", file)
      formData.append("type", typeFile)
      formData.append("id", item._id)
      return formData
    } else {
      setChooseAlert({
        variant: "danger",
        text: "Please upload only pdf, jpg, png",
      })
    }
  }

  /** Reupload document */
  const uplFun = (oldF, newF, typeFile) => {
    if (typeFile === "Insurance") {
      const temp = docInfoIns.filter((x) => x.name !== oldF)
      if (temp.length === 0) {
        setDocInfoIns([])
      } else {
        setDocInfoIns(temp)
      }
    } else if (typeFile === "License") {
      const temp = docInfoIns.filter((x) => x.name !== oldF)
      if (temp.length === 0) {
        setDocInfoLic([])
      } else {
        setDocInfoLic(temp)
      }
    }
    const fd = createFormData(newF, typeFile)
    if (fd) {
      updateDocumentHandler(fd, oldF, typeFile, item._id)
    }
  }
  /** Delete document */
  const delFun = (file, name) => {
    deleteDocumentHandler(file, item._id)
    if (name === "Insurance") {
      const temp = docInfoIns.filter((x) => x.name !== file)
      if (temp.length === 0) {
        setDocInfoIns([])
      } else {
        setDocInfoIns(temp)
      }
    } else if (name === "License") {
      const temp = docInfoIns.filter((x) => x.name !== file)
      if (temp.length === 0) {
        setDocInfoLic([])
      } else {
        setDocInfoLic(temp)
      }
    }
  }

  return (
    <Modal show={showMain} onHide={() => setShowMain(false)} centered>
      <Modal.Header>
        <Modal.Title>Manage Member</Modal.Title>
      </Modal.Header>
      {settings ? (
        <Modal.Body className='bg-white'>
          <ListGroup className='bg-white'>
            <ListGroup.Item
              className='bg-white border-0'
              style={{ color: "black" }}
            >
              <Persona img={item.avatar.path} />
            </ListGroup.Item>
            {item.role === "business" && (
              <>
                <ListGroup.Item
                  className='bg-white border-0'
                  style={{ color: "black" }}
                >
                  {` Business Name: ${item.businessName}`}
                </ListGroup.Item>
                <ListGroup.Item
                  className='bg-white border-0'
                  style={{ color: "black" }}
                >
                  {` Category: ${categoryChosenName}`}
                </ListGroup.Item>
              </>
            )}

            <ListGroup.Item
              className='bg-white border-0'
              style={{ color: "black" }}
            >
              {` Member ID: ${item._id}`}
            </ListGroup.Item>
            <ListGroup.Item
              className='bg-white border-0'
              style={{ color: "black" }}
            >
              {` Name: ${item.name}`}
            </ListGroup.Item>
            <ListGroup.Item
              className='bg-white border-0'
              style={{ color: "black" }}
            >
              {`Email: ${item.email}`}
            </ListGroup.Item>
            <ListGroup.Item
              className='bg-white border-0'
              style={{ color: "black" }}
            >
              {`Phone: ${formatPhoneNumber(item.phone)}`}
            </ListGroup.Item>
            {item.documents && (
              <ListGroup.Item
                className='bg-white border-0'
                style={{ color: "black" }}
              >
                {/* Uploaded files */}
                <Row className='mb-5'>
                  <strong>Uploaded documents</strong>
                  {item.documents.length === 0 && (
                    <p className='text-muted'>No files have been uploaded</p>
                  )}
                  {item.documents.length !== 0 && (
                    <DocumentsTable
                      arr={docInfoIns}
                      name='Insurance'
                      uplFun={uplFun}
                      delFun={delFun}
                    />
                  )}
                  {docInfoLic.length !== 0 && (
                    <DocumentsTable
                      arr={docInfoLic}
                      name='License'
                      uplFun={uplFun}
                      delFun={delFun}
                    />
                  )}
                  {chooseAlert.text !== undefined ? (
                    <Alert variant={chooseAlert.variant}>
                      {chooseAlert.text}
                    </Alert>
                  ) : (
                    ""
                  )}
                </Row>
              </ListGroup.Item>
            )}
          </ListGroup>
        </Modal.Body>
      ) : (
        ""
      )}

      {/* <Modal.Footer> */}
      <>
        <Row className='m-3 '>
          <Col xl={6} md={6} xs={12}>
            {item.approved ? (
              <div>
                <span className='text-success me-1'>
                  Approved <i className='bi bi-check-lg'></i>
                </span>
                <ButtonShadow
                  text='Preview'
                  handleOnClick={() => {
                    setShowPreview(true)
                    setShowMain(false)
                  }}
                />
              </div>
            ) : item.approved !== undefined ? (
              <ButtonGroup className='gap-3'>
                <ButtonShadow
                  text='Approve'
                  bgcolor='#198754'
                  color='#fff'
                  handleOnClick={approvedHandler}
                />
                <ButtonShadow
                  text='Preview'
                  handleOnClick={() => {
                    setShowPreview(true)
                    setShowMain(false)
                  }}
                />
              </ButtonGroup>
            ) : (
              ""
            )}
          </Col>
          <Col xl={6} md={6} xs={12}>
            <ButtonGroup>
              <div
                className={
                  switchFilter
                    ? "tap-button-active ms-auto"
                    : "tap-button ms-auto"
                }
                onClick={() => {
                  setToast(true)
                }}
              >
                Delete Member
              </div>
              <div
                className={switchFilter ? "tap-button-active" : "tap-button"}
                onClick={() => {
                  setSwitchFilter(true)
                  setShowMain(false)
                }}
              >
                Close <i className='bi bi-x-lg'></i>
              </div>
            </ButtonGroup>
          </Col>
        </Row>

        <ToastContainer className='p-3' position='middle-center'>
          <Toast show={toast} onClose={() => setToast(false)}>
            <Toast.Header className='bg-white text-black'>
              <strong className='me-auto '>
                Are you sure you want to delete
              </strong>
            </Toast.Header>
            <Toast.Body className='bg-white'>
              <Row className='justify-content-end '>
                <Col xs={4}>
                  <div
                    className={
                      switchFilter
                        ? "tap-button-active text-center"
                        : "tap-button text-center"
                    }
                    style={{ background: "#228B22", color: "white" }}
                    onClick={() => {
                      setToast(false)
                      setShowMain(false)
                      deleteClickHandler(item)
                    }}
                  >
                    Ok
                  </div>
                </Col>
                <Col xs={4}>
                  <div
                    className={
                      switchFilter
                        ? "tap-button-active text-center"
                        : "tap-button text-center"
                    }
                    onClick={() => {
                      setToast(false)
                      setSwitchFilter(true)
                    }}
                  >
                    Cancel
                  </div>
                </Col>
              </Row>
            </Toast.Body>
          </Toast>
        </ToastContainer>
      </>
      {/* </Modal.Footer> */}
    </Modal>
  )
}

export default MainContext
