import React, { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"

const Util = () => {
  const settingsInfo = JSON.parse(localStorage.getItem("settingsInfo"))
  const [searchParams, setSearchParams] = useSearchParams()
  const keyword = searchParams.get("keyword")
  const param_category = searchParams.get("category")
  const param_othername = searchParams.get("othername")
  const [category, setCategory] = useState()
  const [othername, setOthername] = useState()

  let fullName = ""
  useEffect(() => {
    setCategory(param_category)
    setOthername(param_othername)
  }, [param_category, param_othername])
  /**
   * Full name category
   */
  if (category === undefined) {
    fullName = "Pick a Category"
  } else {
    if (settingsInfo !== undefined) {
      const findedMainCategory = settingsInfo[0].mainServices.find(
        (o) => o.shortName === category
      )
      if (findedMainCategory === undefined) {
        const findedUserCategory = settingsInfo[0].usersServices.find(
          (o) => o.otherName === othername
        )

        fullName = findedUserCategory.name
      } else {
        fullName = findedMainCategory.name
      }
    }
  }

  return fullName
}

export default Util
