import React, { useState, useEffect } from "react"
import { PreviewBusiness, MainContext } from "../adminDashboard"
import { useSelector, useDispatch } from "react-redux"

import { uploadDocument, deleteDocument, getBusinessById } from "../../actions"

const AdminModal = ({
  settingsInfo,
  objectM,
  setShowMain,
  setShowPreview,
  showMain,
  showPreview,
  name,
  id,
  deleteMembers,
  approveHandler,
  googleKey,
}) => {
  const [item, setItem] = useState()
  const [switchFilter, setSwitchFilter] = useState(false)
  const dispatch = useDispatch()
  const { upload } = useSelector((state) => state.uploadDocument)
  const { success } = useSelector((state) => state.deleteDocument)

  useEffect(() => {
    const showO = objectM.find((x) => x._id === id)
    setItem(() => showO)
  }, [])

  const deleteClickHandler = () => {
    setSwitchFilter(true)
    setShowMain(false)
    deleteMembers(item.role, item._id)
  }
  const approvedHandler = () => {
    approveHandler(item._id)
    setShowMain(false)
  }
  const updateDocumentHandler = (file, oldFile, type, id) => {
    dispatch(uploadDocument(file))
    dispatch(deleteDocument(oldFile, id))
    dispatch(getBusinessById(item._id))
  }
  const deleteDocumentHandler = (file, id) => {
    dispatch(deleteDocument(file, id))
    dispatch(getBusinessById(item._id))
  }

  return (
    <>
      {item !== undefined ? (
        showPreview ? (
          <PreviewBusiness
            showPreview={showPreview}
            setShowPreview={setShowPreview}
            setShowMain={setShowMain}
            id={item._id}
            category={item.category}
            googleKey={googleKey}
          />
        ) : (
          <MainContext
            settingsInfo={settingsInfo}
            item={item}
            showMain={showMain}
            setShowMain={setShowMain}
            showPreview={showPreview}
            setShowPreview={setShowPreview}
            deleteClickHandler={deleteClickHandler}
            approvedHandler={approvedHandler}
            updateDocumentHandler={updateDocumentHandler}
            deleteDocumentHandler={deleteDocumentHandler}
          />
        )
      ) : (
        ""
      )}
    </>
  )
}

export default AdminModal
